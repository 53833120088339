@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  display: flex;
  margin: 5px;
  
  .label {
    display: flex;
    //align-items: baseline;
    align-items: center;

    &.invert {
      flex-direction: row-reverse;
    }
  }
}

.input {
  padding: 10px;
  border-radius: 3px;
  border-width: 1px;
  margin-left: 0.5rem;
}


@media screen and (max-width: $fullNavMinWidth) {
  .input {
    max-width: 70vw;
  }
}