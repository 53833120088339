@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  margin: 10px;
  position: relative;
  transition: box-shadow .5s ease;
  width: 100%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

    .mask {
      opacity: 0.5;
    }

    .image {
      transform: scale(1.1);
    }
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transform: scale(1);
    transition: transform .5s ease;
    object-fit: cover;
    min-height: 149px;
  }

  &.tileSize1 {
    font-size: 2rem;
    height: 500px;
  }

  &.tileSize2 {
    font-size: 1.5rem;
    height: 300px;
  }

  &.tileSize3 {
    font-size: 1.2rem;
    height: 200px;
  }

  &.tileSize4 {
    font-size: 1rem;
    height: 150px;
  }

  &.tileSize5 {
    font-size: 0.8rem;
    height: 150px;
  }

  .title {
    position: absolute;
    color: $white;
    font-size: 100%;
    left: 4%;
    bottom: 2%;
    z-index: $tileMask;
    right: 4%;
    width: auto;
    font-weight: bold;

    &.hide {
      display: none;
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent 50%, $black);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    opacity: 1;
    transition: opacity .5s ease;
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .container {
    margin: 10px 0;
  }
}

@media screen and (max-width: $smallScreens) {
  .container {
    .title {
      font-size: 1.3rem !important;
    }

    &.tileSize1, &.tileSize2, &.tileSize3, &.tileSize4, &.tileSize5 {
      height: 200px;
    }
  }
}