@import '/src/styles/colors.module';

//https://loading.io/css/

//### Loader type 0

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.ldsEllipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: ldsEllipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: ldsEllipsis3 0.6s infinite;
}

@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


//### Loader type 1

.ldsRipple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsRipple div {
  position: absolute;
  border: 4px solid $pink2;
  opacity: 1;
  border-radius: 50%;
  animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldsRipple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes ldsRipple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
