.carousel {
    width: calc(100% + 2rem);
    margin: 2rem -1rem;
}

.carousel-control-prev {
    background: linear-gradient(0.25turn, #5c0045, transparent);
}

.carousel-control-next {
    background: linear-gradient(0.25turn, transparent, #5c0045);
}

/*.carousel-indicators {*/
/*    background-color: rgba(127, 6, 106, 0.2);*/
/*}*/