@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

$padding: 20px;
$buttonSize: 20px;

.header {
  display: flex;
  padding: $padding;
  background: $pink;
  font-size: 1.2rem;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: $white;
    padding: 0;
    margin: 0;
  }

  .actionButtons {
    display: flex;
    margin-left: auto;

    .printButton {
      height: $buttonSize;
      width: $buttonSize;
      margin-right: 10px;
      cursor: pointer;
    }

    .closeButton {
      height: $buttonSize;
      width: $buttonSize;
      cursor: pointer;
      fill: $white;
    }
  }
}

.content {
  max-height: 740px;
  overflow: auto;

  .description {
    font-size: 14px;
    padding: 1rem;
  }
}

@media screen and (max-width: $smallScreens) {
  .header {
    flex-direction: column-reverse;
  }
  .row {
    flex-wrap: wrap;

    .label {
      text-align: left !important;
      width: 100% !important;
    }
  }
}