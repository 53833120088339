@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {

  .notFound {
    padding: 2rem;
    color: $pink2;
    font-weight: bold;
  }
}