.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    padding: 0.6rem;
    border: 1px solid #eee;
    border-radius: 0.3rem;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: rgb(84, 0, 68);;
}

.react-datepicker__header {
    background: rgb(172, 0, 136);
}

.react-datepicker__day-name, .react-datepicker__current-month {
    color: #fff;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
}

ul.calendar-tabs {
    margin-bottom: -2rem !important;
    position: relative;
}
/*ul.calendar-tabs li:before {*/
/*    content: '🏊'*/
/*}*/
ul.calendar-tabs button {
    color: rgb(127, 6, 106);
}

ul.calendar-tabs button:hover {
    color: rgb(127, 6, 106)
}

.marker {
    background-color: rgba(127, 6, 106, 0.4)
}

.carousel-control-next, .carousel-control-prev {
    width: 10% !important;
}

.nav-link.active {
    font-weight: 600;
    /*background: repeating-linear-gradient( 45deg, white, white 3px, #9f3b6e14 3px, #9f3b6e14 8px);*/
}

.tab-content {
    border: 1px solid #dee2e6;
    border-width: 0 1px 1px 1px;
    padding: 0 1rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
    background-color: rgba(127, 6, 106, 0.4) !important;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
    background-color: rgba(157, 0, 109, 0.8) !important;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    padding: 0 !important;
}

.react-datepicker__day--today {
    background-color: rgba(157, 0, 109, 0.4) !important;
}
@media screen and (max-width: 1180px) {
    .calendar-tabs .nav-item, .calendar-tabs .nav-item button {
        width: 101%;
        margin-left: -1px;
        font-size: 1.2rem;
        position: relative;
    }
    .calendar-tabs:after {
        content: 'Wybierz obiekt:';
        position: absolute;
        left: 1rem;
        top: -1.3rem;
    }

    .tooltip-arrow {
        display: none !important;
    }

    .nav-link {
        border: none !important;
    }

    .react-datepicker {
        /*margin-left: -0.8rem;*/
    }
}

/*$pink: rgb(172, 0, 136);*/
/*$pink2: rgb(84, 0, 68);*/
/*$pink3: rgb(127, 6, 106);*/