@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  width: 100%;
  //display: flex;
  //flex-grow: 1;

  .mainTitle {
    z-index: 1;
    color: $pink2;
    font-size: 4rem;
    margin-left: 1.5rem;
  }

  .description {
    margin-bottom: 3rem;
    //text-align: center;

    iframe {
      min-height: 60vw;
    }
  }

  .contact {
    font-size: 1.2rem;
    margin: 2rem 1rem 1rem;
    background-color: $white;
    position: relative;
    z-index: 1;
    text-align: center;

    span {
      background-color: $white;
      padding: 0 2rem;
    }

    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 50%;
      border-top: 1px solid rgba(127, 6, 106, 0.2);
      left: 0;
      z-index: -2;
      top: 50%;
    }
  }
}
