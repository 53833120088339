@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}

@media screen and (max-width: $smallScreens) {
  .header {
    //flex-direction: column-reverse;
  }
}