@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  margin: 10px;
  position: relative;
  transition: box-shadow .5s ease;
  width: 100%;
  overflow: hidden;
  height: 100%;
  min-height: 10rem;
  min-width: 13rem;

  background: linear-gradient(311deg, $pink5, $pink6);
  background-size: 400% 400%;

  //-webkit-animation: loader-animation 5s ease infinite;
  //-moz-animation: loader-animation 5s ease infinite;
  //-o-animation: loader-animation 5s ease infinite;
  animation: loader-animation 2s ease infinite;

  .title {
    position: absolute;
    color: $white;
    font-size: 20px;
    left: 4%;
    bottom: 2%;
    z-index: $tileMask;
    right: 4%;
    width: auto;
    font-weight: bold;
    opacity: 0.2;

    &.hide {
      display: none;
    }
  }
}

@-webkit-keyframes loader-animation {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@-moz-keyframes loader-animation {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@-o-keyframes loader-animation {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}
@keyframes loader-animation {
  0%{background-position:0% 51%}
  50%{background-position:100% 50%}
  100%{background-position:0% 51%}
}


@media screen and (max-width: $fullNavMinWidth) {
  .container {
    margin: 10px 0;
  }
}

@media screen and (max-width: $smallScreens) {
  .title {
    font-size: 3vw!important;
  }
}