﻿// dimensions
$menuIconsColumn: 1300px;
$maxWidth: 1200px;
$fullNavMinWidth: 1180px;
$smallScreens: 750px;

// zindex
$tileMask: 1;
$navigationContainer: 3;
$activityTable: 1100;
$fbBox: 1200;
$menuSubItemsContainer: 2;
$searchInput: 10;

$padding: 20px;
$buttonSize: 20px;

// fonts
$standardFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;