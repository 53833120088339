@import "/src/styles/variables.module";

.container {
  display: flex;
  padding: 0 1rem;

  .tile-size-1 {
    font-size: 2rem;
  }

  .tile-size-2 {
    font-size: 1.5rem;
  }

  .tile-size-3 {
    font-size: 1.2rem;
  }

  .tile-size-4 {
    font-size: 1rem;
  }

  .tile-size-5 {
    font-size: 0.8rem;
  }
}


@media screen and (max-width: $fullNavMinWidth) {
  .container {
    flex-direction: column;
  }
}