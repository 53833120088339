@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.button {
  background: $pink2;
  color: $white;
  font-weight: bold;
  padding: 5px;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  font-size: 2rem;
  line-height: 0.5rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1200;
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.buttonHidden {
  opacity: 0;
}