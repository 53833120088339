@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  position: relative;

  .header {
    font-size: 1.4rem;
    color: $pink2;
    padding: 0 0.5rem 1rem 0.5rem;
  }

  .trackFirst {
    & > div > div {
      left: 0
    }
  }

  .trackLast {
    & > div > div {
      right: 0
    }
  }

  .singleTrack td {
    height: 135px !important;
  }

  table {
    position: relative;

    .block {
      //overflow: hidden;
      position: absolute;
      color: $white;
      background-color: $pink;
      z-index: 2;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      line-height: 1;
      box-shadow: inset -2px -2px 1px 0px #ffffff30;

      div {
        position: absolute;
        word-break: break-word;
        bottom: calc(100% + 0.5rem);
        background: $white;
        border-radius: 0.3rem;
        font-size: 0.8rem;
        white-space: break-spaces;
        padding: 0.5rem;
        width: 90%;
        border: 1px solid $pink2;
        line-height: 1.2;
        min-width: 10rem;
        writing-mode: lr;
        color: $black;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-indent: 0.25rem;
        padding: 2px;
      }

      &:after {
        color: $white;
        display: block;
        position: absolute;
        content: attr(data-timefrom);
        left: 0;
        font-size: 0.8rem;
        top: 1px;
      }

      &:before {
        color: $white;
        display: block;
        position: absolute;
        content: attr(data-timeto);
        bottom: 1px;
        left: 0;
        font-size: 0.8rem;
      }

      &.blockDisabled {
        color: white;
        text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
        z-index: 1000;
        background: repeating-linear-gradient( 45deg, #98005885, #98005885 10px, #980058 10px, #980059 20px);
      }
    }
  }

  table thead td, table tbody td {
    text-align: center;
    height: 60px;
    padding: 0;
    line-height: 59px;
    position: relative;
  }

  table tbody td:not(:first-child) {
    //background-image: linear-gradient(rgba(0, 0, 0, 0.1) 1px,
    //        transparent 1px,
    //        transparent);
    background-size: 25% 25%;
  }

  table tbody tr td:first-child {
    width: 4rem;
    font-size: 0.9rem !important;
    text-align: center;
    position: relative;
  }
}


.container.mobileView {
  .header {
    text-align: center;
  }

  table {
    td, th {
      width: 60px;
      height: 45px;
      min-width: 60px;
      text-align: center;
    }

    .block {
      writing-mode: lr;
      height: 100%;
      top: 0;

      span {
        padding: 2px 4px 0 0;
      }
    }
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .container {
    .header {
      font-size: 1.2rem;
      padding: 1rem 0.5rem 0.5rem 0.5rem;

      //small:first-child {
      //  display: none;
      //}

      small, span {
        display: inline-block;
        font-size: 1rem;
        text-align: center;
        font-weight: bold;
      }
    }

    .block {
      writing-mode: vertical-rl;
      font-size: 1.2rem;

      div {
        background-color: #fff !important;
        text-align: center;
        min-width: 16rem !important;
        //position: fixed !important;
        //left: 0;
        //width: 100vw !important;
      }

      &:after, &:before {
        display: none !important;
      }
    }
  }
}
