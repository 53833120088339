@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .header {
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;

    .button {
      background-color: $pink2;
      padding: 0.5rem 2rem;
    }
  }

  .days {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .day {
    cursor: pointer;
    text-align: center;
    border: 1px solid $pink4;
    color: $pink3;
    width: 13%;
    min-height: 3rem;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 0.3rem;
    padding: 1rem 0;
  }

  .day:hover {
    border-color: $pink2;
  }

  .today {
    background-color: $pink6;
  }

  .selected {
    border-width: 3px;
    background-color: $pink4;
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .days {
    flex-wrap: wrap !important;
    justify-content: space-around !important;

    .day {
      width: 26%;
      margin-bottom: 0.25rem;
    }
  }
}