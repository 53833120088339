@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

$radius: 5px;

.container {
  position: fixed;
  top: 14rem;
  left: 0;
  display: flex;
  color: $white;
  font-weight: 600;
  z-index: $activityTable;

  &:hover {
    .table {
      width: 100%;
    }
  }

  .label {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background: $pink7;
    padding: 10px;
    border-radius: 0 $radius $radius 0;
    margin-top: auto;
    margin-left: -1px;
    border: 1px solid $gray5;
    border-left: none;
  }

  .facilityName {
    background: $pink7;
    padding: 10px;
  }

  .usersCount {
    background: $white;
    color: $gray3;
    padding: 15px 10px;
    font-size: 80%;

    &::first-line {
      color: $pink2;
    }

    span {
      color: $pink2;
    }
  }

  .table {
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: width 0.5s ease;
    border: 1px solid $gray5;
    border-left: none;
    border-radius: 0 0 $radius 0;
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .container {
    top: 45vh;

    .label {
      padding: 10px 5px;
    }

    .usersCount {
      padding: 5px;
    }
  }
}
