//.container {
//  position: relative;
//
//  .img {
//    opacity: 0;
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    object-fit: cover;
//    transition: 1s all;
//    max-width: 100%;
//    max-height: 100%;
//  }
//}
.img {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1s all;
}
.imagesList {
  display: flex;
  flex-direction: row;

  img {
    max-width: 5rem;
  }
}