@import './styles/colors.module';
@import "/src/styles/variables.module";

html {
  scroll-behavior: smooth;
}

body {
  background: $gray7;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .contentContainer {
    width: 100%;
    max-width: $maxWidth;
    margin: auto;
  }

  .contentContainer {
    flex: 1;
  }
}

.footer {
  text-align: center;
  border-top: 2px solid $pink;
  width: 100%;
  display: flex;
  color: $white;
  background-color: $pink7;

  a, img {
    padding: 1rem;
    height: auto;
    min-height: 4.1rem;
    display: flex;
    align-items: center;
    color: $white09;
    font-weight: 500;
    text-decoration: none;
    opacity: 0.8;
  }

  a:hover, img:hover {
    opacity: 1;
  }

  > div:last-child {
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  > div:first-child {
    text-align: left;
    padding-top: 1rem;
    padding-left: 1rem;
    display: block;
    margin-bottom: -4rem;
    width: 20rem;
    z-index: 2;
    font-size: 0.8rem;
    height: 11.5rem;

    a {
      margin-left: -1rem;
      min-height: 1rem;
      padding-top: 0;
    }
  }
  a:hover {
    color: $white !important;
  }

  img {
    max-height: 65px;
  }
}

marquee {
  color: $white;
}

@media screen and (max-width: $fullNavMinWidth) {
  .contentContainer {
    width: 100%;
  }

  .footer {
    flex-direction: row;
    flex-wrap: wrap;
    //a, div, img {
    //  padding: 0.5rem 10%;
    //}

    > div:first-child {
      margin-bottom: -2.5rem;
      height: 8.5rem;
    }
  }
}