.icon {
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
}

.invertColors *:not([class*="notInvertColors"]) {
  background-color: #000 !important;
  color: yellow !important;
}

.fbBoxContainerRightWorkAround {
  right: 0;
}