@import "/src/styles/variables.module";

.icon {
  height: 40px;
  width: 40px;
  margin: 0.25rem 1rem;
}
.menuView {
  display: none;
}

.sideView {
  .fbBox {
    position: fixed;
    right: -242px;
    top: 14rem;
    display: flex;
    align-items: end;
    transition: right 0.5s ease;
    z-index: $fbBox;
  }

  .icon {
    position: fixed;
    right: 0.5rem;
    margin: 0;

    &.insta {
      top: calc(14rem + 115px);
    }

    &.twitter {
      height: 37px;
      top: calc(14rem + 165px);
    }

    &.ytIcon {
      top: calc(14rem + 215px);
    }
  }

  .fbIcon {
    top: calc(14rem + 60px);
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .icon {
    &.insta {
      top: calc(50vh + 115px);
    }

    &.twitter {
      height: 37px;
      top: calc(50vh + 165px);
    }

    &.ytIcon {
      top: calc(50vh + 215px);
    }
  }
  .fbBox {
    top: 50vh;
  }
  .sideView {
    display: none;
  }
  .menuView {
    display: block;
  }
}
