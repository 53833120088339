@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  position: relative;
  color: $white;
  font-weight: bold;
  cursor: pointer;

  &.isSelected {
    background: $pink3;
  }
  
  &:hover {
    color: $pink;
  }

  &:hover .subItemsContainer {
    display: block;
  }

  .item {
    color: $white09;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    white-space: nowrap;

    &:hover {
      color: $white;
    }
  }

  .subItemsContainer {
    display: none;
    position: absolute;
    background: $pink;
    border: none;
    font-size: 14px;
    min-width: 215px;
    white-space: nowrap;
    z-index: $menuSubItemsContainer;
    //transition: all 1s ease-in;

    .subItem {
      padding: 7px 20px;
      border-top: 1px solid $pink3;
      color: $white08;
      background: $pink7;
      text-decoration: none;
      font-weight: normal;

      &:last-of-type {
        border-bottom: none;
      }
      &:hover {
        color: $white;
      }
    }
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .container {
    //width: auto;
    width: 100%;

    .item {
      border-top: 1px solid $gray2;
      width: 100%;
      padding: 0.8rem 3rem;
    }

    .subItemsContainer {
      position: relative;
      display: block;
      border: none;

      .subItem {
        border: none;
        padding: 0.5rem 4.5rem;
        border-top: 1px solid $gray1;
      }
    }
  }
}