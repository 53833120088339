@import "/src/styles/colors.module";

.label {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 0.5rem;
}
.input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.span {
  font-size: 1rem;
  color: $white;
  background-color: $white;
  padding: 0.2rem 0.3rem;
  margin: 0.5rem;
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  border: 1px solid $pink4;
}

.active {
  color: $pink2;
  border: 1px solid $pink2;
  position: relative;
}

.span:after {
  position: absolute;
  width: 1.7rem;
  height: 1.5rem;
  content: "✓";
  text-align: center;
  font-size: 1.5rem;
  left: -0.3rem;
  top: -0.7rem;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.span.active:after {
  opacity: 1;
}