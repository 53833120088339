@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.outerContainer {
  background: $pink7;
  display: flex;
  justify-content: center;
  z-index: $navigationContainer;

  a {
    text-decoration: none !important;
  }

  .container {
    display: flex;
    align-items: center;
    width: 100%; //$maxWidth;
    max-width: $maxWidth;
    flex-direction: row;

    .logo {
      //width: 135px;
      height: 60px;
      //filter: contrast(5);
      //filter: brightness(0) invert(1);
    }

    .navigationItems {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      //transition: all 0.5s ease-in;
    }

    .mobileMenu {
      display: none;
    }
  }

  .logoBip {
    //position: absolute;
    //right: 0;
    width: 40px;
    height: 40px !important;
    background-image: url('https://miejskoaktywni.pl/uploads/blp.png');
    background-position: 0 44px;
    margin: 0.5rem;

    //&:hover {
    //  background-position: 0 44px;
    //}
  }

  .fontSize {
    cursor: pointer;
    margin: 0 0.3rem;
    padding-top: 0.1rem;

    img {
      width: 1.8rem;
      height: 1.8rem;
      filter: invert(100%);
      background: none !important;
    }
  }

  .handIcon {
    padding-top: 0.2rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
      filter: invert(100%);
      background: none !important;
    }
  }

  .menuIcons {
    text-align: center;
  }

  .menuIconsTopMobile {
    display: none;
  }

  .menuIconsTop {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column-reverse;
    background: #9d006d;
    border-radius: 0 0 5px 5px;
    text-align: center;
    align-items: center;

    & > img, & > span, & > a {
      height: 2.7rem;
      line-height: 2;
      //display: block;
    }
  }

  .searchItem {
    padding: 0;
    position: relative;
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .container {
    flex-direction: column !important;
    align-items: flex-start !important;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .navigationItems {
    flex-direction: column;
    display: none !important;
    width: 100%;

    &.show {
      display: flex !important;
    }
  }

  .mobileMenu {
    display: flex !important;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    top: 10px;
    right: 2.5rem;
    height: 38px;
    width: 30px;

    .line {
      height: 2px;
      background: $white;
    }

    .fontSize {
      margin: 0 3rem;
    }

    .menuIcons {
      padding: 0.5rem;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .menuIconsTop {
      right: 4rem;
      position: relative;
    }
  }

  .outerContainer {
    flex-direction: column;

    .logoBip {
      right: 4rem;
      background-position: 0 44px;
    }
  }
}

@media screen and (max-width: $menuIconsColumn) {
  .outerContainer {
    .menuIconsTopMobile {
      display: flex;
      * {
        margin: 0 1rem;
        height: 2.5rem;
      }
    }

    .menuIconsTop {
      display: none !important;
      //flex-direction: column !important;
      //background-color: $pink7;
      //text-align: center;
      //justify-content: center;
      //
      //& > img, & > span, & > a {
      //  margin: 0;
      //}
    }
  }
}