@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  padding: 1rem;
  -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  background-color: $white;

  .loader {
    text-align: center;
    margin: 5rem 0
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    position: relative;
    align-items: center;

    .printButton {
      height: $buttonSize;
      width: $buttonSize;
      margin-right: 10px;
      cursor: pointer;
      position: absolute;
      right: -1rem;
      top: -0.5rem;
      z-index: 2;
    }

    .title {
      font-size: 2rem;
      color: $pink2;
    }

    .date {
      text-align: center;

      .day {
        font-size: 2rem;
        color: $pink2;
      }
    }
  }

  .mainImage img {
    width: 100%;
  }

  .gallery {
    margin: 1rem auto;
    width: 80%;
  }

  .content {
    > p:first-child, > div:first-child {
      font-weight: bold;
    }
  }
}
@media screen and (max-width: $fullNavMinWidth) {
  .header {
    &:after {
      content: " ";
      position: absolute;
      right: -4rem;
      top: -4rem;
      background-color: rgb(84, 0, 68);
      width: 6rem;
      height: 6rem;
      transform: rotate(45deg);
      z-index: 1;
    }
    .printButton {
      filter: invert(100%);
    }
  }
}