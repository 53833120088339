@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.button {
  background: $pink;
  color: $white;
  font-weight: bold;
  font-size: 14px;
  padding: 5px; 
  border-radius: 3px;
  border: none;
  cursor: pointer;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}