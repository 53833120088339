@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  margin-bottom: 2rem;

  .tooltipDate {
    position: absolute;
    padding: 0.5rem 1rem;
    z-index: 15;
    top: 0.1rem;
    left: calc(100% + 0.6rem);
    border-radius: 0.5rem;
    width: 23.125rem;
    background-color: $pink7;
    color: $white;
    font-size: 12px;

    div {
      padding-bottom: 0.8rem;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .mainImageContainer {
    min-height: 50vh;
    position: relative;
    width: 100%;
    overflow-y: clip;
    display: flex;
    align-items: end;
    justify-content: center;

    .mainTitle {
      color: $white;
      //text-shadow: 1px 1px 2px $pink, 0 0 1em $pink2, 0 0 0.2em $white;
    }

    .mainImage {
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100vw;
      height: 100%;
      background-position: center;

      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        height: 10rem;
        max-height: 100%;
        width: 100%;
        background: linear-gradient(180deg, transparent 20%, rgb(0, 0, 0));
      }
    }

    > img {
      position: absolute;
      left: calc(calc(calc(100vw - 100%) / 2 ) * -1)
    }
  }

  .tilesRows > div:first-child > div:not(:first-child) {
    height: unset;
  }

  .contact {
    font-size: 1.2rem;
    margin: 2rem 1rem 1rem;
    background-color: $white;
    position: relative;
    z-index: 1;
    text-align: center;

    span {
      background-color: $white;
      padding: 0 2rem;
    }

    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      height: 50%;
      border-top: 1px solid rgba(127, 6, 106, 0.2);
      left: 0;
      z-index: -2;
      top: 50%;
    }
  }

  .description {
    margin-top: 1rem;
    padding: 0 0.5rem;
  }

  .mainTitle {
    z-index: 1;
    color: $pink2;
    font-size: 4rem;
    margin-left: 0.5rem;
  }
  .trackCalendar {
    margin-top: 0.5rem;
    position: relative;
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .mainImageContainer {
    max-height: 25vh !important;
    min-height: 25vh !important;
  }
  .trackCalendar {
    margin-left: -0.8rem;
  }
  .mainImageContainer {
    .mainTitle {
      font-size: 1.9rem;
      text-align: center;
    }
  }
}
