@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.searchContainer {
  .searchIcon {
    padding: 20px;
  }

  .inputContainer {
    background: $pink7;
    height: 0;
    position: absolute;
    right: 0;
    bottom: -100%;
    transition: height 0.5s ease;
    z-index: $searchInput;
    overflow: hidden;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 0 0 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
    .input {
      height: 2rem;
      font-size: 16px;
      width: 13rem;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      border: 1px solid rgb(145, 158, 171);
      outline: none;
      border-radius: 5px;
      font-weight: 400;
      margin: 0;
      padding: 0.25rem 1.2rem 0.25rem 0.8rem;
    }

    &.show {
      height: 100%;
    }
    
    .insideSearch {
      filter: invert(100%);
      position: absolute;
      right: 20px;
      padding: 10px 0;
      background-color: transparent !important;
    }
  }
}

@media screen and (max-width: $fullNavMinWidth) {
  .searchContainer {
    .inputContainer {
      top: 0.1rem;
      height: 100%;
      width: 100%;

      input {
        width: 100% !important;
      }
    }
  }
}