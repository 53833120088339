@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.note {
  font-size: 1.2rem;
  margin: 1rem 0.5rem;
}

.row {
  display: flex;
  justify-content: left;
  margin-left: -0.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.form {
  padding: 0 1rem;
}

.room {
  background-color: $pink4;
  padding: 1rem;
  margin-left: -1rem;
  width: calc(100% + 2rem);
  line-height: 1.5rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.totalCost {
  margin: 0;
  width: 100%;
  text-align: right;
  line-height: 45px;
}

.checkboxContainer {
  margin-top: 10px;
  
  .checkbox {
    align-self: baseline;
    margin: 5px 10px 0 0;
  }
}

.submitButtonContainer {
  display: flex;
  padding: 1rem 0 0 0;
  
  .button {
    margin: auto;
    padding: 1rem 3rem;
  }
}

.amountInput {
  margin-left: 5px;
}

.roomInfoContainer {

  .roomInfoName {
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0.5rem 1rem 0;
    margin: 0;
  }
  .roomInfoDescription {
    padding: 0 1rem;
  }
}

.error {
  font-weight: bold;
  text-align: center;
  
  button {
    margin: 10px;
    padding: 10px 20px;
  }
}

@media screen and (max-width: $fullNavMinWidth) {

}
