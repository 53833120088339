@import "/src/styles/colors.module";

.linkWithIcon {
  display: flex;
  flex-direction: column;
  color: $white09;
  line-height: 1.2 !important;
  margin-top: 0.7rem;

  & img {

  }

  &:hover {
    color: $white;
  }

  span:last-child {
    font-size: 0.9rem;
  }
}
