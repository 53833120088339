﻿$black: rgb(0, 0, 0);

$gray1: rgb(18, 18, 24);
$gray2: rgb(33, 43, 54);
$gray3: rgb(69, 79, 91);
$gray4: rgb(92, 109, 123);
$gray5: rgb(145, 158, 171);
$gray6: rgb(196, 205, 213);
$gray7: rgb(223, 227, 232);
$gray8: rgb(244, 246, 248);

$white: rgb(255, 255, 255);
$white08: rgba(255, 255, 255, 0.8);
$white09: rgba(255, 255, 255, 0.9);

$red: rgb(255, 0, 0);

$pink: rgb(172, 0, 136);
$pink2: rgb(84, 0, 68);
$pink3: rgb(127, 6, 106);
$pink4: rgba(127, 6, 106, 0.2);
$pink5: rgb(172, 0, 136, 0.3);
$pink6: rgba(127, 6, 106, 0.1);
$pink7: rgb(157, 0, 109);
