@import "/src/styles/colors.module";

$buttonSize: 20px;

.header {
  margin-top: 30vh;
  font-size: 120%;
  display: flex;
  justify-content: center;
  align-items: end;

  .icon {
    font-size: 300%;
  }
}
