@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

$padding: 20px;
$buttonSize: 20px;

.header {
  display: flex;
  padding: $padding;
  background: $pink;
  color: $white;
  
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    margin-right: 20px;
    font-weight: bold;
  }
  
  .actionButtons {
    display: flex;
    margin-left: auto;

    .printButton {
      height: $buttonSize;
      width: $buttonSize;
      margin-right: 10px;
      cursor: pointer;
    }
    .closeButton {
      height: $buttonSize;
      width: $buttonSize;
      cursor: pointer;
    }
  }
}

.content {
  padding: $padding;

  .description {
    font-size: 14px;
  }
}

@media screen and (max-width: $smallScreens) {
  .header {
    flex-direction: column-reverse;
  }
}