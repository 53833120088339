@import "/src/styles/colors.module";
@import "/src/styles/variables.module";

.container {
  display: flex;
  justify-content: center;
  padding: 0 1.6rem;

  .leftColumn {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    .button {
      padding: 10px;
    }
  }

  .rightColumn {
    margin-top: 1rem;
    margin-left: 1rem;
    flex: 3;
    color: $white;
    background-color: $pink4;

    .selectedDates {
      padding: 0.5rem 1rem;
      background-color: $pink2;
      color: $white;
      font-size: 1.3rem;
    }

    .roomLoader {
      margin-top: 6rem;
      text-align: center;
    }
  }
}

.mainTitle {
  z-index: 1;
  color: $pink2;
  font-size: 4rem;
  margin-left: 1.5rem;
}

.mainImageContainer {
  min-height: 50vh;
  position: relative;
  width: 100%;
  overflow-y: clip;
  display: flex;
  align-items: end;
  justify-content: center;

  .mainTitle {
    color: $white;
    //text-shadow: 1px 1px 2px $pink, 0 0 1em $pink2, 0 0 0.2em $white;
  }

  .mainImage {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100%;
    background-position: center;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      height: 10rem;
      max-height: 100%;
      width: 100%;
      background: linear-gradient(180deg, transparent 20%, rgb(0, 0, 0));
    }
  }

  > img {
    position: absolute;
    left: calc(calc(calc(100vw - 100%) / 2) * -1)
  }
}

.description {
  padding: 0 1.5rem;
}

.contact {
  font-size: 1.2rem;
  margin: 2rem 1rem 1rem;
  background-color: $white;
  position: relative;
  z-index: 1;
  text-align: center;

  span {
    background-color: $white;
    padding: 0 2rem;
  }

  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 50%;
    border-top: 1px solid rgba(127, 6, 106, 0.2);
    left: 0;
    z-index: -2;
    top: 50%;
  }
}

.roomListContainer {
  display: flex;
  justify-content: center;
  height: 100%;
}

@media screen and (max-width: $fullNavMinWidth) {
  .container {
    flex-wrap: wrap;

    .mainTitle {
      font-size: 1.9rem;
    }

    .rightColumn {
      margin: 0;
    }
  }
}