@import '/src/styles/colors.module';
@import "/src/styles/variables.module";

.error {
  color: $pink2;
  font-size: 4rem;
  margin-bottom: 5rem;

  small {
    color: $pink;
    font-size: 2rem;
  }
}